import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M2458 4111 c-68 -7 -80 -17 -52 -39 27 -22 41 -140 18 -149 -8 -3
-14 -11 -14 -18 0 -13 28 -12 138 1 40 5 42 7 42 40 0 24 -5 34 -15 34 -8 0
-15 -9 -15 -20 0 -16 -7 -20 -35 -20 -32 0 -35 2 -35 30 0 18 6 33 15 36 22 9
18 24 -5 24 -15 0 -20 7 -20 25 0 21 5 25 29 25 16 0 34 -7 41 -15 18 -21 27
-11 22 25 -5 33 -3 33 -114 21z"/>
<path d="M2785 4024 c-1 -60 -4 -79 -18 -89 -28 -20 -20 -30 36 -43 68 -16 82
-15 117 3 66 34 77 125 20 165 -24 17 -106 40 -144 40 -7 0 -11 -27 -11 -76z
m100 30 c24 -10 35 -48 25 -85 -11 -37 -25 -49 -58 -49 -22 0 -22 3 -16 53 6
48 19 87 29 87 2 0 11 -3 20 -6z"/>
<path d="M2265 4080 c-3 -5 -20 -10 -36 -10 -32 0 -79 -38 -79 -63 0 -8 9 -26
21 -41 16 -21 29 -26 62 -26 23 0 48 -4 56 -9 22 -14 -5 -43 -38 -41 -44 3
-49 1 -44 -20 7 -26 22 -35 36 -21 6 6 23 11 37 11 36 0 70 34 70 70 0 40 -40
70 -95 70 -46 0 -56 5 -48 26 3 9 20 14 48 14 38 0 43 2 38 20 -7 25 -19 34
-28 20z"/>
<path d="M2035 3989 c-4 -5 -12 -8 -18 -6 -7 2 -24 -3 -39 -11 -22 -13 -28
-24 -28 -48 0 -45 38 -74 97 -74 37 0 44 -3 41 -17 -2 -13 -15 -19 -46 -21
-46 -3 -49 -8 -28 -38 12 -15 17 -17 27 -6 7 6 23 12 35 12 34 0 74 37 74 69
0 41 -30 63 -90 66 -57 3 -65 7 -57 30 4 10 19 15 46 15 34 0 38 3 34 20 -6
21 -37 27 -48 9z"/>
<path d="M3116 3991 c-3 -5 -4 -16 0 -24 6 -17 -68 -84 -100 -89 -32 -5 -30
-31 3 -51 39 -23 44 -22 37 8 -8 30 12 42 43 25 28 -14 27 -46 -1 -53 -22 -6
-22 -6 -2 -28 18 -20 65 -49 80 -49 3 0 4 9 1 20 -3 10 6 56 20 100 17 57 22
85 14 93 -18 22 -89 58 -95 48z m44 -60 c0 -24 -21 -61 -35 -61 -28 0 -30 31
-4 51 27 21 39 24 39 10z"/>
<path d="M1803 3869 c-39 -25 -41 -36 -9 -49 26 -10 66 -85 66 -126 0 -34 10
-35 58 -7 40 24 47 43 17 43 -20 0 -78 116 -70 141 9 26 -20 25 -62 -2z"/>
<path d="M3306 3825 c-22 -33 -21 -35 14 -28 22 4 30 2 30 -8 -1 -20 -75 -99
-93 -99 -33 0 -29 -23 9 -55 42 -36 54 -36 54 -2 0 24 70 107 90 107 8 0 11
-9 8 -25 -7 -35 9 -41 32 -13 l20 23 -68 62 c-37 35 -70 63 -73 63 -4 0 -14
-11 -23 -25z"/>
<path d="M2430 3793 c-120 -14 -322 -94 -434 -171 -139 -96 -282 -269 -347
-422 -57 -135 -79 -245 -79 -399 0 -275 90 -497 279 -695 95 -98 173 -154 292
-210 324 -152 697 -121 998 83 62 42 206 182 222 215 9 18 8 19 -3 6 -34 -39
-150 -131 -212 -168 -81 -49 -206 -98 -296 -118 -79 -17 -300 -19 -395 -4
-265 42 -548 249 -670 491 -144 284 -123 624 56 889 106 155 274 289 439 349
149 53 166 56 365 56 186 0 192 -1 286 -32 99 -32 173 -66 244 -111 40 -26
159 -124 183 -152 7 -8 5 0 -5 19 -24 45 -140 150 -240 217 -114 77 -262 134
-398 154 -68 9 -214 11 -285 3z"/>
<path d="M1693 3771 c-3 -12 -11 -17 -23 -14 -11 3 -33 -5 -50 -17 -25 -18
-30 -28 -30 -64 0 -51 33 -106 71 -120 59 -20 154 29 142 74 -7 25 -15 25 -38
0 -42 -47 -119 -7 -113 59 4 44 28 60 62 41 34 -18 40 -10 20 28 -18 36 -34
41 -41 13z"/>
<path d="M3496 3661 c-4 -5 -4 -16 -1 -23 6 -16 -83 -68 -115 -68 -31 0 -34
-21 -7 -47 25 -25 53 -24 43 2 -3 8 1 19 9 25 10 8 18 6 34 -11 27 -29 26 -37
-4 -49 -33 -12 -32 -20 8 -58 40 -39 57 -41 57 -4 0 15 18 56 39 92 l40 65
-42 43 c-43 43 -52 48 -61 33z m24 -91 c-14 -33 -31 -38 -50 -15 -10 12 -6 18
21 34 40 24 46 21 29 -19z"/>
<path d="M2541 3649 c-69 -12 -83 -24 -17 -15 101 13 268 -19 397 -77 130 -57
273 -179 350 -297 280 -426 119 -998 -339 -1212 -122 -56 -210 -78 -327 -79
-83 -1 -92 -3 -55 -10 109 -19 260 -6 374 32 145 49 250 114 356 222 92 92
159 201 201 327 27 81 33 114 37 221 5 148 -6 226 -50 341 -66 174 -178 314
-328 413 -174 114 -406 166 -599 134z"/>
<path d="M2090 3339 c-213 -36 -330 -227 -330 -542 0 -322 137 -514 381 -534
165 -14 302 63 374 211 35 72 43 60 44 -63 l1 -83 233 5 c243 5 260 8 360 58
133 68 208 219 207 418 -1 184 -62 317 -186 401 -84 59 -108 63 -369 67 -148
2 -246 0 -255 -6 -10 -7 -15 -29 -16 -71 0 -33 -4 -61 -7 -63 -3 -1 -19 18
-35 43 -59 91 -130 139 -237 159 -67 12 -88 12 -165 0z m924 -220 c101 -37
152 -123 163 -274 10 -152 -28 -265 -111 -322 -57 -39 -120 -53 -241 -53 l-95
0 0 268 c0 147 -3 297 -7 335 l-6 67 121 0 c98 0 132 -4 176 -21z m-760 -10
c33 -26 54 -76 62 -145 l7 -53 111 -3 111 -3 0 -100 0 -100 -105 -3 -106 -3
-11 -69 c-17 -113 -56 -153 -148 -153 -68 0 -117 34 -149 102 -19 41 -21 64
-21 226 0 176 1 181 27 235 34 69 66 89 139 90 42 0 63 -5 83 -21z"/>
<path d="M1755 1596 c-119 -53 -142 -241 -42 -344 47 -49 142 -66 226 -42 43
12 51 24 51 79 0 37 -2 42 -17 36 -10 -4 -44 -10 -75 -13 -51 -4 -59 -2 -82
21 -22 22 -26 35 -26 85 0 49 4 64 25 86 13 14 35 26 48 26 27 0 57 -28 61
-57 2 -13 13 -19 39 -21 l37 -3 0 75 0 76 -40 0 c-29 0 -40 -4 -40 -15 0 -20
-7 -19 -37 5 -30 23 -84 26 -128 6z"/>
<path d="M2380 1599 c-47 -19 -65 -49 -65 -110 0 -73 22 -99 116 -135 52 -19
69 -30 69 -45 0 -12 -9 -21 -26 -25 -28 -7 -74 9 -74 26 0 5 -18 10 -40 10
l-40 0 0 -60 c0 -60 0 -60 30 -60 16 0 35 6 42 14 11 12 17 12 42 -1 40 -21
110 -12 142 18 53 50 55 153 3 191 -13 10 -54 29 -89 41 -51 17 -65 26 -65 42
0 30 69 34 87 6 9 -15 23 -21 50 -21 l38 0 0 56 0 57 -54 -7 c-33 -3 -57 -2
-61 4 -8 13 -72 12 -105 -1z"/>
<path d="M2745 1603 c-78 -19 -99 -150 -35 -208 11 -10 47 -27 82 -40 47 -17
64 -28 66 -43 2 -15 -3 -23 -20 -27 -26 -7 -72 7 -83 24 -3 6 -22 11 -41 11
l-35 0 3 -57 c3 -54 5 -58 28 -59 14 0 33 4 42 10 12 7 24 6 42 -3 16 -9 42
-12 72 -8 62 7 101 45 110 107 11 83 -16 116 -129 155 -48 16 -62 25 -62 40 0
30 69 34 87 6 9 -15 23 -21 50 -21 l38 0 0 55 0 55 -40 0 c-22 0 -40 -5 -40
-10 0 -6 -13 -4 -30 5 -28 15 -65 18 -105 8z"/>
<path d="M2060 1564 c0 -27 5 -37 20 -41 19 -5 20 -14 20 -119 0 -108 -1 -114
-21 -114 -18 0 -20 -5 -17 -42 l3 -43 95 0 95 0 3 43 c3 37 1 42 -17 42 -20 0
-21 6 -21 114 0 105 1 114 20 119 15 4 20 14 20 41 l0 36 -100 0 -100 0 0 -36z"/>
<path d="M3037 1593 c-12 -12 -7 -50 8 -63 8 -7 15 -9 15 -5 0 4 7 2 15 -5 13
-11 15 -33 13 -119 -3 -103 -4 -105 -28 -111 -27 -6 -35 -28 -26 -66 l7 -24
162 2 162 3 0 80 c0 79 0 80 -26 83 -24 3 -28 -2 -36 -35 -8 -37 -10 -38 -56
-41 l-47 -3 0 50 0 51 36 0 c33 0 35 2 32 28 -2 20 -9 27 -25 28 -13 1 -27 2
-33 3 -16 2 -12 68 5 75 34 13 76 -6 90 -41 11 -27 20 -34 37 -31 21 3 23 9
26 76 l3 72 -164 0 c-90 0 -167 -3 -170 -7z"/>
<path d="M2155 949 l0 -81 53 4 c94 8 134 79 76 133 -21 20 -38 25 -78 25
l-51 0 0 -81z m101 25 c8 -31 -10 -74 -31 -74 -11 0 -15 12 -15 50 0 43 3 50
20 50 13 0 22 -9 26 -26z"/>
<path d="M2360 1016 c0 -8 5 -16 11 -18 13 -4 -15 -91 -31 -96 -5 -2 -10 -10
-10 -18 0 -9 11 -14 35 -14 35 0 50 22 23 33 -10 4 -9 8 2 16 22 15 35 8 35
-20 0 -20 6 -25 34 -27 34 -3 68 16 44 25 -6 2 -21 33 -33 69 l-22 64 -44 0
c-31 0 -44 -4 -44 -14z m60 -58 c0 -10 -7 -18 -15 -18 -16 0 -19 17 -9 45 5
13 8 14 15 3 5 -7 9 -21 9 -30z"/>
<path d="M2520 1005 c0 -14 5 -25 10 -25 6 0 10 5 10 10 0 6 7 10 15 10 11 0
15 -12 15 -49 0 -28 -5 -53 -10 -56 -20 -12 1 -25 41 -25 36 0 39 2 29 19 -16
31 -13 111 5 111 8 0 15 -4 15 -10 0 -5 5 -10 10 -10 6 0 10 11 10 25 0 24 -2
25 -75 25 -73 0 -75 -1 -75 -25z"/>
<path d="M2710 1016 c0 -8 5 -16 12 -18 9 -3 9 -15 -1 -51 -8 -26 -18 -47 -22
-47 -5 0 -9 -7 -9 -15 0 -10 10 -15 30 -15 31 0 43 23 18 33 -10 4 -9 8 2 16
25 18 45 8 42 -22 -3 -25 0 -27 32 -27 34 0 55 13 37 24 -5 3 -17 35 -26 71
l-18 65 -48 0 c-36 0 -49 -4 -49 -14z m64 -42 c8 -21 -1 -34 -18 -28 -8 3 -12
14 -9 25 6 23 19 25 27 3z"/>
<path d="M864 603 c16 -120 17 -123 37 -123 15 0 19 8 20 38 l1 37 10 -37 c6
-21 16 -38 23 -38 14 0 20 22 30 113 5 48 4 57 -9 57 -12 0 -16 -11 -16 -46 0
-27 -4 -43 -10 -39 -5 3 -10 24 -10 46 0 28 -4 39 -15 39 -11 0 -15 -12 -15
-46 0 -27 -4 -43 -10 -39 -5 3 -10 24 -10 46 0 29 -4 39 -16 39 -14 0 -15 -8
-10 -47z"/>
<path d="M1010 565 l0 -85 30 0 c20 0 30 5 30 15 0 8 -7 15 -15 15 -9 0 -15 9
-15 25 0 16 6 25 15 25 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0
8 7 15 15 15 8 0 15 7 15 15 0 10 -10 15 -30 15 l-30 0 0 -85z"/>
<path d="M1146 618 c-16 -108 -17 -138 -1 -138 8 0 15 7 15 15 0 8 7 15 15 15
8 0 15 -7 15 -15 0 -8 5 -15 10 -15 11 0 13 55 4 124 -6 40 -10 46 -30 46 -18
0 -24 -7 -28 -32z m34 -50 c0 -28 -3 -39 -10 -32 -9 9 -3 74 6 74 2 0 4 -19 4
-42z"/>
<path d="M1230 565 c0 -65 3 -86 13 -82 8 2 12 28 12 82 0 54 -4 80 -12 83
-10 3 -13 -18 -13 -83z"/>
<path d="M1280 565 c0 -95 17 -117 22 -27 l3 57 10 -54 c11 -61 31 -78 40 -34
3 15 10 42 14 58 8 26 9 22 10 -27 2 -94 21 -68 21 28 0 83 -1 85 -22 82 -18
-2 -24 -11 -26 -38 -4 -42 -22 -66 -22 -29 0 44 -12 69 -31 69 -17 0 -19 -8
-19 -85z"/>
<path d="M1470 636 c0 -8 5 -18 11 -21 6 -4 9 -33 8 -68 -2 -40 1 -62 9 -65 9
-3 12 17 12 67 0 41 4 71 10 71 6 0 10 7 10 15 0 10 -10 15 -30 15 -19 0 -30
-5 -30 -14z"/>
<path d="M1562 638 c-7 -7 -12 -39 -12 -74 0 -67 14 -90 50 -79 18 6 20 15 20
80 0 57 -3 74 -16 79 -22 8 -28 8 -42 -6z m36 -79 c-2 -24 -7 -44 -13 -44 -12
0 -19 69 -10 93 11 29 26 -4 23 -49z"/>
<path d="M1690 565 c0 -69 3 -85 15 -85 10 0 15 11 15 35 0 28 4 35 19 35 21
0 31 19 31 55 0 26 -25 45 -59 45 -20 0 -21 -6 -21 -85z m50 35 c0 -11 -4 -20
-10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z"/>
<path d="M1790 565 c0 -50 4 -85 10 -85 6 0 10 18 10 40 0 22 5 40 10 40 6 0
10 -18 10 -40 0 -29 4 -40 15 -40 12 0 15 15 15 79 0 83 -5 91 -51 91 -17 0
-19 -8 -19 -85z m40 40 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10
15 6 0 10 -7 10 -15z"/>
<path d="M1892 638 c-7 -7 -12 -39 -12 -73 0 -59 11 -85 36 -85 28 0 44 32 44
85 0 70 -34 107 -68 73z m38 -73 c0 -30 -4 -55 -10 -55 -5 0 -10 25 -10 55 0
30 5 55 10 55 6 0 10 -25 10 -55z"/>
<path d="M1975 613 c2 -21 7 -59 10 -85 5 -38 10 -48 25 -48 20 0 24 14 35
114 5 46 4 57 -7 54 -7 -3 -15 -25 -18 -54 -6 -54 -20 -39 -20 22 0 24 -5 34
-15 34 -12 0 -14 -9 -10 -37z"/>
<path d="M2070 565 c0 -50 4 -85 10 -85 6 0 10 35 10 85 0 50 -4 85 -10 85 -6
0 -10 -35 -10 -85z"/>
<path d="M2110 565 l0 -85 28 0 c42 0 52 17 52 85 0 68 -10 85 -52 85 l-28 0
0 -85z m50 0 c0 -30 -4 -55 -10 -55 -5 0 -10 25 -10 55 0 30 5 55 10 55 6 0
10 -25 10 -55z"/>
<path d="M2210 566 l0 -86 35 0 c24 0 35 5 35 15 0 8 -9 15 -20 15 -15 0 -20
7 -20 25 0 16 6 25 15 25 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15
0 9 9 15 21 15 33 0 16 25 -19 28 l-32 3 0 -85z"/>
<path d="M2358 639 c-24 -13 -26 -130 -3 -149 19 -16 52 -4 59 23 9 33 8 37
-9 37 -8 0 -15 -9 -15 -20 0 -11 -4 -20 -10 -20 -5 0 -10 25 -10 55 0 30 5 55
10 55 6 0 10 -9 10 -21 0 -12 5 -19 13 -17 19 7 20 34 2 52 -19 19 -24 19 -47
5z"/>
<path d="M2430 577 c0 -91 24 -122 64 -81 22 22 23 154 1 154 -12 0 -15 -14
-15 -70 0 -40 -4 -70 -10 -70 -6 0 -10 30 -10 70 0 56 -3 70 -15 70 -12 0 -15
-15 -15 -73z"/>
<path d="M2543 643 c-20 -7 -15 -58 7 -78 21 -19 27 -55 10 -55 -5 0 -10 7
-10 15 0 8 -4 15 -10 15 -20 0 -11 -48 10 -55 51 -16 67 51 23 97 -14 15 -23
31 -18 35 4 5 10 2 12 -4 6 -17 33 -17 33 0 0 27 -28 42 -57 30z"/>
<path d="M2620 636 c0 -8 5 -18 10 -21 6 -3 10 -35 10 -71 0 -36 4 -64 10 -64
6 0 10 30 10 70 0 40 4 70 10 70 6 0 10 7 10 15 0 10 -10 15 -30 15 -19 0 -30
-5 -30 -14z"/>
<path d="M2713 643 c-9 -3 -13 -29 -13 -78 0 -63 3 -75 19 -80 41 -13 51 3 51
80 0 57 -3 74 -16 79 -18 7 -23 7 -41 -1z m35 -84 c-2 -24 -7 -44 -13 -44 -12
0 -19 69 -10 93 11 29 26 -4 23 -49z"/>
<path d="M2790 565 c0 -69 3 -85 15 -85 12 0 15 14 16 63 l2 62 10 -62 c12
-79 32 -83 45 -10 9 50 10 51 12 15 4 -60 4 -61 18 -65 9 -4 12 17 12 81 0 85
0 86 -25 86 -20 0 -25 -5 -26 -27 0 -16 -4 -37 -8 -48 -7 -16 -10 -10 -16 28
-6 41 -10 47 -31 47 -23 0 -24 -2 -24 -85z"/>
<path d="M2940 565 l0 -85 35 0 c24 0 35 5 35 15 0 8 -9 15 -20 15 -15 0 -20
7 -20 25 0 16 6 25 15 25 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15
0 8 6 15 14 15 8 0 16 7 20 15 4 12 -3 15 -29 15 l-35 0 0 -85z"/>
<path d="M3020 565 c0 -69 3 -85 15 -85 11 0 15 11 15 40 0 22 5 40 10 40 6 0
10 -18 10 -40 0 -28 4 -40 15 -40 11 0 14 16 13 78 0 82 -6 92 -50 92 l-28 0
0 -85z m50 40 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10
-7 10 -15z"/>
<path d="M3168 625 c-10 -22 -8 -29 15 -53 27 -28 36 -62 17 -62 -5 0 -10 7
-10 15 0 8 -7 15 -15 15 -20 0 -19 -24 1 -44 19 -19 36 -20 52 -4 19 19 14 51
-13 82 -26 31 -31 46 -15 46 6 0 10 -4 10 -10 0 -5 7 -10 15 -10 22 0 19 27
-4 40 -30 15 -40 12 -53 -15z"/>
<path d="M3266 628 c-3 -13 -7 -51 -10 -85 -5 -52 -3 -63 9 -63 8 0 15 7 15
15 0 8 7 15 15 15 8 0 15 -7 15 -15 0 -8 5 -15 10 -15 11 0 13 55 4 124 -6 40
-10 46 -30 46 -16 0 -25 -7 -28 -22z m34 -55 c0 -30 -3 -44 -10 -37 -8 8 -3
84 6 84 2 0 4 -21 4 -47z"/>
<path d="M3350 635 c0 -8 5 -15 10 -15 6 0 10 -30 10 -71 0 -52 3 -70 13 -67
7 3 13 29 15 71 2 37 7 67 12 67 6 0 10 7 10 15 0 10 -11 15 -35 15 -24 0 -35
-5 -35 -15z"/>
<path d="M3430 564 c0 -64 3 -85 13 -82 8 3 13 31 14 86 2 66 -1 82 -12 82
-12 0 -15 -17 -15 -86z"/>
<path d="M3486 634 c-22 -22 -20 -37 9 -64 25 -23 34 -60 15 -60 -5 0 -10 7
-10 15 0 8 -7 15 -15 15 -20 0 -19 -24 1 -44 19 -19 36 -20 52 -4 19 19 14 54
-14 88 -15 17 -23 34 -19 38 4 4 10 1 12 -5 6 -18 33 -16 33 2 0 8 -9 19 -19
25 -25 13 -27 13 -45 -6z"/>
<path d="M3570 565 c0 -69 3 -85 15 -85 11 0 15 11 15 40 0 29 4 40 15 40 8 0
15 7 15 15 0 8 -7 15 -15 15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 7 15 15
0 10 -10 15 -30 15 l-30 0 0 -85z"/>
<path d="M3656 628 c-3 -13 -7 -51 -10 -85 -5 -52 -3 -63 9 -63 8 0 15 7 15
15 0 8 7 15 15 15 8 0 15 -7 15 -15 0 -8 5 -15 10 -15 11 0 13 55 4 124 -6 40
-10 46 -30 46 -16 0 -25 -7 -28 -22z m30 -86 c-12 -11 -15 5 -8 44 l7 39 3
-39 c2 -21 1 -41 -2 -44z"/>
<path d="M3758 639 c-14 -8 -18 -24 -18 -75 0 -59 2 -65 25 -76 19 -8 28 -8
40 2 20 16 20 60 0 60 -8 0 -15 -9 -15 -20 0 -11 -4 -20 -10 -20 -5 0 -10 25
-10 55 0 30 5 55 10 55 6 0 10 -9 10 -21 0 -12 5 -19 13 -17 20 7 21 41 1 55
-21 15 -24 16 -46 2z"/>
<path d="M3830 635 c0 -8 5 -15 10 -15 6 0 10 -30 10 -70 0 -56 3 -70 15 -70
12 0 15 14 15 70 0 40 4 70 10 70 6 0 10 7 10 15 0 10 -11 15 -35 15 -24 0
-35 -5 -35 -15z"/>
<path d="M3910 565 c0 -69 3 -85 15 -85 12 0 15 16 15 85 0 69 -3 85 -15 85
-12 0 -15 -16 -15 -85z"/>
<path d="M3972 638 c-16 -16 -16 -130 0 -146 34 -34 68 3 68 73 0 70 -34 107
-68 73z m38 -73 c0 -30 -4 -55 -10 -55 -5 0 -10 25 -10 55 0 30 5 55 10 55 6
0 10 -25 10 -55z"/>
<path d="M4060 566 c0 -51 4 -86 10 -86 6 0 10 17 10 38 0 27 3 33 10 22 5 -8
10 -25 10 -37 0 -14 6 -23 15 -23 12 0 15 16 15 85 0 85 -16 119 -22 48 l-4
-38 -10 34 c-5 19 -15 36 -22 39 -9 3 -12 -20 -12 -82z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
